$nove-color: #605ca8;
$orange-color: #eb6020;
//https://github.com/azouaoui-med/react-pro-sidebar
$sidebar-bg-color: transparent !default;
$sidebar-color: #000000 !default;
$sidebar-width: 270px !default;
$sidebar-collapsed-width: 80px !default;
$highlight-color: #000000 !default;
$submenu-bg-color: transparent !default;
$submenu-bg-color-collapsed: transparent !default;
$icon-bg-color: transparent !default;
$icon-size: 35px !default;
$submenu-indent: 24px !default;
$breakpoint-xs: 480px !default;
$breakpoint-sm: 576px !default;
$breakpoint-md: 768px !default;
$breakpoint-lg: 992px !default;
$breakpoint-xl: 1200px !default;
$breakpoint-xxl: 1600px !default;
@import "react-redux-toastr/src/styles/index";
@import "~react-pro-sidebar/dist/scss/styles.scss";
@import "~slick-carousel/slick/slick.scss";
@import "~slick-carousel/slick/slick-theme.scss";

@font-face {
  font-family: Lato-Regular;
  src: local("Lato-Regular"), url(./fonts/Lato-Regular.ttf) format("woff");
}

@font-face {
  font-family: RobotoMono-Regular;
  src: local("RobotoMono-Regular"),
  url(./fonts/RobotoMono-Regular.ttf) format("woff");
}

body {
  margin: 0;
  padding: 0 !important;
  font-family: Lato-Regular, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  & * {
    font-family: Lato-Regular, sans-serif;
  }

  .pro-sidebar {
    background-color: rgba(0, 0, 0, 0.05);

    > .pro-sidebar-inner {
      @media (max-width: $breakpoint-md) {
        background: white;
      }
    }

    .pro-menu {
      padding: 0;

      .pro-menu-item > .pro-inner-item {
        padding-top: 6px;
        padding-bottom: 6px;
      }
    }

    .pro-sidebar-content,
    .pro-sidebar-footer {
      li.pro-menu-item {
        border-left: 5px solid transparent;

        &:hover {
          background-color: rgba(0, 0, 0, 0.1);
        }

        &.active {
          border-left-color: $orange-color;
        }

        ul {
          padding: 0 !important;
        }
      }
    }
  }
}

.RobotoMono {
  font-family: RobotoMono-Regular, sans-serif !important;
}

.pt-10 {
  padding-top: 10px;
}

.background-nove {
  background-color: $nove-color;
  color: #ffffff;

  * {
    color: #ffffff;
  }
}

.text-danger {
  color: #d32f2f;
}

.pb-10 {
  padding-bottom: 10px;
}

.container-dashboard {
  display: flex;
  flex-flow: column;
  align-items: stretch;
}

.d-flex {
  display: flex;
}

.flex-grow {
  flex-grow: 1;
}

.h-0 {
  height: 0;
}

.w-100 {
  width: 100%;
}

.overflow-auto {
  overflow: auto;
}

.p5 {
  padding: 5px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

.img-contain {
  max-width: 100%;
  max-height: 100%;
  margin: auto;
}

.img-center {
  display: block;
  margin: auto;
}

.position-relative {
  position: relative;
}

.slick-prev,
.slick-next {
  z-index: 1;
}

.slick-next {
  right: 0;
}

.slick-prev {
  left: 0;
}

.slick-prev:before,
.slick-next:before {
  color: black;
}

div.slick-slider > div.slick-list > div.slick-track {
  display: flex;
  align-items: stretch;
  margin-top: 1px;

  > div {
    text-align: center;
  }

  .slick-slide {
    height: auto;

    > div {
      justify-content: space-between;

      &,
      > div {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
      }
    }
  }
}

.slick-dots {
  position: initial;
}

.slick-dots-image {
  margin-top: 4px;

  > li {
    width: 50px;
    height: 50px;
  }
}

.center-input input {
  text-align: center;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: #fff;
  background-color: $infoBgColor;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

// https://ckeditor.com/docs/ckeditor5/latest/installation/advanced/content-styles.html#the-full-list-of-content-styles
// region ckeditor
.ck-content {
  code {
    background-color: hsla(0, 0%, 78%, 0.3);
    padding: 0.15em;
    border-radius: 2px;
  }

  blockquote {
    overflow: hidden;
    padding-right: 1.5em;
    padding-left: 1.5em;
    margin-left: 0;
    margin-right: 0;
    font-style: italic;
    border-left: solid 5px hsl(0, 0%, 80%);
  }

  .text-tiny {
    font-size: 0.7em;
  }

  .text-small {
    font-size: 0.85em;
  }

  .text-big {
    font-size: 1.4em;
  }

  .text-huge {
    font-size: 1.8em;
  }

  .marker-yellow {
    background-color: var(--ck-highlight-marker-yellow);
  }

  .marker-green {
    background-color: var(--ck-highlight-marker-green);
  }

  .marker-pink {
    background-color: var(--ck-highlight-marker-pink);
  }

  .marker-blue {
    background-color: var(--ck-highlight-marker-blue);
  }

  .pen-red {
    color: var(--ck-highlight-pen-red);
    background-color: transparent;
  }

  .pen-green {
    color: var(--ck-highlight-pen-green);
    background-color: transparent;
  }

  .image-style-block-align-left {
    max-width: calc(100% - var(--ck-image-style-spacing));
    margin-left: 0;
    margin-right: auto;
  }

  .image-style-block-align-right {
    max-width: calc(100% - var(--ck-image-style-spacing));
    margin-right: 0;
    margin-left: auto;
  }

  .image-style-align-left {
    clear: none;
    float: left;
    margin-right: var(--ck-image-style-spacing);
  }

  .image-style-align-right {
    clear: none;
    float: right;
    margin-left: var(--ck-image-style-spacing);
  }

  .image-style-side {
    float: right;
    margin-left: var(--ck-image-style-spacing);
    max-width: 50%;
  }

  .image-style-align-center {
    margin-left: auto;
    margin-right: auto;
  }

  p {
    + {
      .image-style-align-left {
        margin-top: 0;
      }

      .image-style-align-right {
        margin-top: 0;
      }

      .image-style-side {
        margin-top: 0;
      }
    }
  }

  .image-inline.image-style-align-left {
    margin-top: var(--ck-inline-image-style-spacing);
    margin-bottom: var(--ck-inline-image-style-spacing);
    margin-right: var(--ck-inline-image-style-spacing);
  }

  .image-inline.image-style-align-right {
    margin-top: var(--ck-inline-image-style-spacing);
    margin-bottom: var(--ck-inline-image-style-spacing);
    margin-left: var(--ck-inline-image-style-spacing);
  }

  .image {
    > figcaption {
      display: table-caption;
      caption-side: bottom;
      word-break: break-word;
      color: var(--ck-color-image-caption-text);
      background-color: var(--ck-color-image-caption-background);
      padding: 0.6em;
      font-size: 0.75em;
      outline-offset: -1px;
    }

    display: table;
    clear: both;
    text-align: center;
    margin: 0.9em auto;
    min-width: 50px;

    img {
      display: block;
      margin: 0 auto;
      max-width: 100%;
      min-width: 100%;
    }
  }

  .image.image_resized {
    max-width: 100%;
    display: block;
    box-sizing: border-box;

    img {
      width: 100%;
    }

    > figcaption {
      display: block;
    }
  }

  .image-inline {
    display: inline-flex;
    max-width: 100%;
    align-items: flex-start;

    picture {
      display: flex;
      flex-grow: 1;
      flex-shrink: 1;
      max-width: 100%;
    }

    img {
      flex-grow: 1;
      flex-shrink: 1;
      max-width: 100%;
    }
  }

  span[lang] {
    font-style: italic;
  }

  .todo-list {
    list-style: none;

    li {
      margin-bottom: 5px;

      .todo-list {
        margin-top: 5px;
      }
    }

    .todo-list__label {
      > input {
        -webkit-appearance: none;
        display: inline-block;
        position: relative;
        width: var(--ck-todo-list-checkmark-size);
        height: var(--ck-todo-list-checkmark-size);
        vertical-align: middle;
        border: 0;
        left: -25px;
        margin-right: -15px;
        right: 0;
        margin-left: 0;

        &::before {
          display: block;
          position: absolute;
          box-sizing: border-box;
          content: "";
          width: 100%;
          height: 100%;
          border: 1px solid hsl(0, 0%, 20%);
          border-radius: 2px;
          transition: 250ms ease-in-out box-shadow, 250ms ease-in-out background,
          250ms ease-in-out border;
        }

        &::after {
          display: block;
          position: absolute;
          box-sizing: content-box;
          pointer-events: none;
          content: "";
          left: calc(var(--ck-todo-list-checkmark-size) / 3);
          top: calc(var(--ck-todo-list-checkmark-size) / 5.3);
          width: calc(var(--ck-todo-list-checkmark-size) / 5.3);
          height: calc(var(--ck-todo-list-checkmark-size) / 2.6);
          border-style: solid;
          border-color: transparent;
          border-width: 0 calc(var(--ck-todo-list-checkmark-size) / 8) calc(var(--ck-todo-list-checkmark-size) / 8) 0;
          transform: rotate(45deg);
        }
      }

      > input[checked] {
        &::before {
          background: hsl(126, 64%, 41%);
          border-color: hsl(126, 64%, 41%);
        }

        &::after {
          border-color: hsl(0, 0%, 100%);
        }
      }

      .todo-list__label__description {
        vertical-align: middle;
      }
    }
  }

  .media {
    clear: both;
    margin: 0.9em 0;
    display: block;
    min-width: 15em;
  }

  .page-break {
    position: relative;
    clear: both;
    padding: 5px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &::after {
      content: "";
      position: absolute;
      border-bottom: 2px dashed hsl(0, 0%, 77%);
      width: 100%;
    }
  }

  .page-break__label {
    position: relative;
    z-index: 1;
    padding: 0.3em 0.6em;
    display: block;
    text-transform: uppercase;
    border: 1px solid hsl(0, 0%, 77%);
    border-radius: 2px;
    font-family: Helvetica, Arial, Tahoma, Verdana, sans-serif;
    font-size: 0.75em;
    font-weight: bold;
    color: hsl(0, 0%, 20%);
    background: hsl(0, 0%, 100%);
    box-shadow: 2px 2px 1px hsla(0, 0%, 0%, 0.15);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .table {
    > figcaption {
      display: table-caption;
      caption-side: top;
      word-break: break-word;
      text-align: center;
      color: var(--ck-color-table-caption-text);
      background-color: var(--ck-color-table-caption-background);
      padding: 0.6em;
      font-size: 0.75em;
      outline-offset: -1px;
    }

    margin: 0.9em auto;
    display: table;

    table {
      border-collapse: collapse;
      border-spacing: 0;
      width: 100%;
      height: 100%;
      border: 1px double hsl(0, 0%, 70%);

      td {
        min-width: 2em;
        padding: 0.4em;
        border: 1px solid hsl(0, 0%, 75%);
      }

      th {
        min-width: 2em;
        padding: 0.4em;
        border: 1px solid hsl(0, 0%, 75%);
        font-weight: bold;
        background: hsla(0, 0%, 0%, 5%);
      }
    }
  }

  pre {
    padding: 1em;
    color: hsl(0, 0%, 20.8%);
    background: hsla(0, 0%, 78%, 0.3);
    border: 1px solid hsl(0, 0%, 77%);
    border-radius: 2px;
    text-align: left;
    direction: ltr;
    tab-size: 4;
    white-space: pre-wrap;
    font-style: normal;
    min-width: 200px;

    code {
      background: unset;
      padding: 0;
      border-radius: 0;
    }
  }

  hr {
    margin: 15px 0;
    height: 4px;
    background: hsl(0, 0%, 87%);
    border: 0;
  }

  .mention {
    background: var(--ck-color-mention-background);
    color: var(--ck-color-mention-text);
  }

  figure.image {
    width: 100%;
  }

  img {
    max-width: 100%;
  }

  @for $i from 1 through 8 {
    .table-big-border-#{$i} td {
      border-width: #{$i}px !important;
    }
  }

}

.autocomplete-no-padding-right {
  [class^="MuiOutlinedInput"] {
    padding-right: 9px !important;
  }
}

.ck-content[dir="rtl"] {
  blockquote {
    border-left: 0;
    border-right: solid 5px hsl(0, 0%, 80%);
  }

  .table {
    th {
      text-align: right;
    }
  }
}

.ck-content[dir="ltr"] {
  .table {
    th {
      text-align: left;
    }
  }
}

.full-link:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent;
}

@media print {
  .ck-content {
    .page-break {
      padding: 0;

      &::after {
        display: none;
      }
    }
  }
}

.breadcrumbs {
  ol {
    justify-content: flex-start;
  }
}

.breadcrumbs-nowrap {
  ol {
    flex-wrap: nowrap;
  }
}

// todo add this in documentation
.custom-ck-editor {
  .ck-toolbar__items {
    > :nth-child(23) {
      display: none !important;
    }
  }

  &.custom-ck-editor-small .ck-toolbar__items {
    > :nth-child(4),
    > :nth-child(9),
    > :nth-child(10),
    > :nth-child(16),
    > :nth-child(21),
    > :nth-child(22),
    > :nth-child(26),
    > :nth-child(27),
    > :nth-child(28) {
      display: none !important;
    }
  }

  &.custom-ck-editor-sticky {
    .ck-editor__top {
      position: sticky;
      top: 0;
      background-color: white;
      z-index: 1;
    }

    .ck.ck-sticky-panel .ck-sticky-panel__content_sticky {
      position: absolute;
    }
  }
}

// endregion

// region cropper
.cropper-container {
  .cropper-line,
  .cropper-point {
    background-color: $nove-color !important;
  }

  .cropper-view-box {
    outline: $nove-color solid 1px !important;
  }
}

// endregion

.cursor-pointer {
  cursor: pointer;
}

//https://stackoverflow.com/questions/5445491/height-equal-to-dynamic-width-css-fluid-layout
.objectFitCover {
  object-fit: contain;
  aspect-ratio: 1 / 1;
}

.flex-wrap-wrap {
  flex-wrap: wrap;
  align-content: center;
}

.icon-button-hover {
  &:hover {
    background-color: $nove-color !important;
  }
}

.MuiAutocomplete-transform-rotate-0 button {
  -webkit-transform: rotate(0deg) !important;
  -moz-transform: rotate(0deg) !important;
  -ms-transform: rotate(0deg) !important;
  transform: rotate(0deg) !important;
}

.hover-transparent {
  &:hover {
    background-color: transparent !important;
  }
}

.color-orange {
  color: $orange-color !important;

  * {
    color: $orange-color !important;

    &:hover {
      color: $orange-color !important;
    }
  }

  &:hover {
    color: $orange-color !important;
  }
}

//todo utiliser les variables de /react-pro-sidebar/dist/scss/variables.scss
@media (max-width: $breakpoint-md) {
  .pro-sidebar.md {
    position: absolute;
  }
  .breadcrumbs {
    ol {
      font-size: 0.7rem;
    }
  }
}

@media (min-width: $breakpoint-md) {
  .no-padding-text-field {
    & > div {
      padding: 0 !important;
    }
  }
  .MuiAccordionSummary-root {
    min-height: auto !important;

    .MuiAccordionSummary-content {
      margin: 0 !important;
    }
  }
}

.flex-wrap-slider .slick-slide > div > div > div {
  flex-wrap: wrap;
}

pre {
  margin: 0;
  background-color: #f6f8fa;
  border-radius: 6px;
  padding: 0 16px;
  width: 100%;
  box-sizing: border-box;
  overflow: auto;
}

.MuiAlert-message {
  flex: 1;
}

.is-pinned-shadow {
  box-shadow: rgb(0 0 0 / 20%) 0 2px 4px -1px, rgb(0 0 0 / 14%) 0px 4px 5px 0px,
  rgb(0 0 0 / 12%) 0px 1px 10px 0px;
}

.is-pinned-radius {
  border-radius: 0 0 4px 4px;
}

.margin-y-0 {
  > div {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}

.display-content {
  display: contents;
}

.autocomplete-height-adjust {
  * {
    white-space: initial !important;
  }

  .MuiButtonBase-root {
    height: auto;
  }
}

.react-pdf {
  .react-pdf__Document {
    &:not(:first-child) {
      border-left: gray 1px solid;
    }

    display: flex;
    flex-wrap: wrap;

    canvas {
      margin: auto;
      width: 100% !important;
      width: -moz-available !important;
      width: -webkit-fill-available !important;
      width: fill-available !important;
      height: auto !important;
    }
  }
}

.ck-rounded-corners .ck.ck-balloon-panel, .ck.ck-balloon-panel.ck-rounded-corners {
  z-index: 9999;
}

@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}

.MuiSlider-root {
  .MuiSlider-thumb[data-index="0"] {
    .MuiSlider-valueLabelOpen {
      transform: translateY(140%) scale(1) !important;

      &:before {
        top: -8px;
      }
    }
  }

  .MuiSlider-markLabel[data-index="0"] {
    top: -20px
  }
}

.pro-menu-item.favoris {
  > div {
    align-items: stretch !important;

    .pro-item-content {
      align-items: center;
      display: flex;
    }
  }
}

.slick-slide img {
  margin: auto;
}

.react-reveal {
  display: inline-block;
}

.light-background {
  background-color: rgba(0, 0, 0, 0.12) !important;
}

.phone-input-error input {
  outline-color: rgb(211, 47, 47) !important;
  border: 1px solid rgb(211, 47, 47);
}

.phone-input input {
  padding: 16.5px 14px;

  &:focus-visible {
    outline-color: $nove-color;
  }
}

.pro-item-content {
  display: flex;
  flex-wrap: wrap;
}
